import { Tooltip } from '@systemeio/ui-shared'
import { useState } from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BellIcon from 'shared/icons/bell-icon'
import DoubleCheckmarkIcon from 'shared/icons/double-checkmark-icon'
import SettingsIcon from 'shared/icons/settings-icon'
import ThinArrowRightIcon from 'shared/icons/thin-arrow-right-icon'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import { twMerge } from 'tailwind-merge'
import { useNotificationsActions } from '../hooks/use-notification-actions'
import { useNotifications } from '../hooks/use-notifications'
import NotificationEmptyList from './notification-empty-list'
import NotificationItem from './notification-item'
import NotificationsListSkeleton from './notifications-list-skeleton'
import UnreadIndicator from './unread-indicator'

export interface NotificationMenuProps {
  className?: string
}

const defaultFilter = {
  onlyUnRead: false,
}

const NotificationMenu = ({ className }: NotificationMenuProps) => {
  const { t } = useLocoTranslation()
  const [shouldFetch, setShouldFetch] = useState(false)

  const { pagination } = usePagination(true)
  const { notificationsMeta, notifications } = useNotifications({
    shouldFetch,
    limit: 3,
    pagination,
    filter: defaultFilter,
  })
  const { markAllNotificationsAsRead } = useNotificationsActions()

  return (
    <>
      <Tooltip
        anchor={{
          gap: 13,
          to: 'top end',
          offset: 135,
        }}
        className={className}
        stayOnHover={true}
        popoverClassName={'p-0 shadow-lg rounded-lg'}
        wrapperClassName={'h-full'}
        label={
          <div className="w-[500px] flex flex-col text-sm rounded-lg bg-white py-4 shadow-lg">
            <div className="flex justify-between flex-row px-6">
              <h4 className="text-[18px] text-darkblue">{t('dashboard.notifications.title')}</h4>
              <div className="inline-flex transition items-baseline flex-row gap-1 stroke-darkblue hover:stroke-blue text-darkblue hover:text-blue">
                <DoubleCheckmarkIcon />
                <span className="cursor-pointer" onClick={markAllNotificationsAsRead}>
                  {t('dashboard.notifications.mark_all_read')}
                </span>
              </div>
            </div>
            {notifications === undefined ? (
              <NotificationsListSkeleton />
            ) : notifications.items.length > 0 ? (
              notifications.items.map(notification => (
                <NotificationItem key={notification.id} notification={notification} />
              ))
            ) : (
              <NotificationEmptyList />
            )}
            <div className="flex justify-between px-6 pt-4">
              <LinkWithoutPrefetch
                className="inline-flex group flex-row gap-1 items-center primary-link text-darkblue hover:text-blue"
                href="/profile/notification-settings"
              >
                <SettingsIcon
                  className="fill-darkblue group-hover:fill-blue"
                  width={18}
                  height={18}
                />
                <h4>{t('dashboard.notifications.manage_notifications.title')}</h4>
              </LinkWithoutPrefetch>
              <LinkWithoutPrefetch
                className="inline-flex items-center flex-row gap-3 primary-link stroke-darkblue hover:stroke-blue text-darkblue hover:text-blue"
                href={`/notifications`}
              >
                <h4 className="capitalize">{t('dashboard.notifications.view_all')}</h4>
                <ThinArrowRightIcon />
              </LinkWithoutPrefetch>
            </div>
          </div>
        }
      >
        {show => (
          <div
            onMouseEnter={() => setShouldFetch(true)}
            className="h-full flex items-center justify-around gap-2.5 focus:outline-none border-b-4 border-transparent focus-visible:border-blue"
          >
            <div className="relative w-5 text-sm font-bold text-white/90">
              <BellIcon
                className={twMerge(
                  'transition fill-gray-200 stroke-gray-200 hover:fill-white hover:stroke-white w-4',
                  show && 'fill-white stroke-white',
                )}
              />
              {notificationsMeta?.hasUnread && (
                <UnreadIndicator className="absolute top-0 right-0 p-0 " iconClassName="w-2 h-2" />
              )}
            </div>
          </div>
        )}
      </Tooltip>
    </>
  )
}

export default NotificationMenu
