import {
  ErrorResponseContentInterface,
  ErrorResponseInterface,
  ErrorResponseWithViolationsType,
} from '../types/error-response-content-interface'
import { ErrorWithViolations } from './error-with-violations'

export class BadRequest<F> extends Error {
  public errors: ErrorResponseContentInterface<F>

  constructor(errorResponse: ErrorResponseInterface<F>) {
    super(errorResponse.errors.common?.join(', '))
    this.errors = errorResponse.errors
  }
}

export class BadRequestWithViolations extends ErrorWithViolations {
  constructor(errorResponse: ErrorResponseWithViolationsType) {
    super(errorResponse)
  }
}
