import { useRouter } from 'next/router'
import { publicRoutes } from 'shared/hooks/use-user/constants/public-routes'
import { UnauthorizedUser, UserInterface } from 'shared/types/user-interface'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR, { useSWRConfig } from 'swr'
import { DASHBOARD_USER_DATA_API, PUBLIC_USER_DATA_API } from '../constants/api'

export const usePublicUser = () => {
  const router = useRouter()
  const isPublic = publicRoutes.some(route => router.pathname.includes(route))

  const { cache } = useSWRConfig()

  const dashboardUser = cache.get(DASHBOARD_USER_DATA_API)?.data

  const { data: publicUser, mutate: publicMutate } = useSWR<UserInterface | UnauthorizedUser>(
    isPublic && PUBLIC_USER_DATA_API,
    baseFetcher,
    {
      fallbackData: dashboardUser ? dashboardUser : undefined,
    },
  )

  return { publicUser, publicMutate }
}
