import { LocaleEnum } from '@systemeio/ui-shared'
import React from 'react'
import { useLanguageSwitcher } from 'shared/components/language-switcher/hooks/use-language-switcher'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from '../../hooks/use-user'
import { LanguageSwitcher } from './language-switcher'

export function MobileLanguageSwitcher() {
  const { i18n } = useLocoTranslation()
  const { isLoading } = useUser()
  const { handleChangeLanguage, isChangingLanguage } = useLanguageSwitcher()

  return (
    <LanguageSwitcher
      changeLanguage={handleChangeLanguage}
      selectedLanguage={i18n.language as LocaleEnum}
      isFetching={isChangingLanguage || !i18n || isLoading}
      buttonClassName={'leading-6'}
    />
  )
}
