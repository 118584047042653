import { ActionMenu, ActionMenuItem, ActionMenuItemProps } from '@systemeio/ui-shared'
import ItemCount from 'shared/components/item-count'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { twMerge } from 'tailwind-merge'
import { getNotificationIcon } from '../constants/notification-icons'
import { useNotificationsActions } from '../hooks/use-notification-actions'
import { NotificationItemInterface } from '../types/notification-types'

export interface NotificationItemProps {
  notification: NotificationItemInterface
  actions?: ActionMenuItemProps[]
}

const NotificationItem = ({ notification, actions }: NotificationItemProps) => {
  const { user } = useUser()
  const { t } = useLocoTranslation()
  const { id, eventType, message, read, createdAt } = notification
  const { getNotificationLink } = useNotificationsActions()

  const handleNotificationClick = async () => {
    const { link } = await getNotificationLink(id)
    window.open(link, '_blank')
  }

  return (
    <div
      className={twMerge(
        'group transition flex flex-row justify-between px-6 py-4 bg-white border-b border-gray last:border-b-0 text-darkblue hover:bg-blue-100 hover:cursor-pointer',
        read && 'text-gray group-hover:text-darkblue',
      )}
    >
      <div className="inline-flex flex-row gap-4 items-center" onClick={handleNotificationClick}>
        {getNotificationIcon(eventType)}
        <div className="pr-3">
          <div>
            <span
              className="group-hover:text-darkblue transition"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
          {user?.id !== undefined && (
            <div className="group-hover:text-darkblue transition">
              {dateTimeFormat(createdAt, {
                timeFormat: user.timeFormat,
                dateFormat: user.dateFormat,
                timeZone: user.timezone,
              })}
            </div>
          )}
        </div>
      </div>
      <div className="inline-flex flex-row gap-4 items-center">
        {!read && <ItemCount>{t('dashboard.notifications.new')}</ItemCount>}
        {actions && actions.length > 0 && (
          <ActionMenu
            menuItems={actions.map(action => (
              <ActionMenuItem
                key={`${action.label}-na-action`}
                label={action.label}
                onClick={action.onClick}
              />
            ))}
          />
        )}
      </div>
    </div>
  )
}

export default NotificationItem
