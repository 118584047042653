import {
  FormSelectDataType,
  FormSelectIdType,
  HeadlessUI,
  ListLoader,
  ListOptionsWrapper,
  getPanelHeight,
  renderOption,
} from '@systemeio/ui-shared'
import { ListLoaderProps } from '@systemeio/ui-shared/components/form-select/components/list-loader'
import { ListOptionProps } from '@systemeio/ui-shared/components/form-select/utils/render-list-option'
import { ReactNode, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface ListBoxSelectProps<T extends FormSelectIdType>
  extends ListLoaderProps,
    Pick<ListOptionProps, 'optionClassName' | 'optionIconWrapperClassName'> {
  value: T | undefined
  onChange: (value: T) => void
  optionsClassName?: string
  data: FormSelectDataType<T>[] | undefined
  children: (open: boolean) => ReactNode
  disabled?: boolean
  buttonClassName?: string
}

export function ListBoxSelect<T extends FormSelectIdType>({
  value,
  onChange,
  children,
  optionsClassName,
  data,
  loaderClassName,
  loaderWrapperClassName,
  optionClassName,
  optionIconWrapperClassName,
  disabled,
  buttonClassName,
}: ListBoxSelectProps<T>) {
  const selectedData = data?.find(el => el.id === value)

  const [listRef, setListRef] = useState<HTMLElement | null>(null)

  const height = getPanelHeight(listRef, data)

  return (
    <HeadlessUI.Listbox
      disabled={disabled}
      value={selectedData}
      onChange={data => onChange(data?.id)}
    >
      {({ open }) => (
        <>
          <HeadlessUI.ListboxButton
            className={twMerge('outline-none focus-visible:ring-2 ring-blue', buttonClassName)}
          >
            {children(open)}
          </HeadlessUI.ListboxButton>
          <ListOptionsWrapper
            ref={setListRef}
            style={{ height }}
            as={HeadlessUI.ListboxOptions}
            optionsClassName={twMerge('h-fit outline-none', optionsClassName)}
          >
            {data ? (
              data.map(el =>
                renderOption({
                  item: el,
                  as: HeadlessUI.ListboxOption,
                  optionClassName,
                  optionIconWrapperClassName,
                }),
              )
            ) : (
              <ListLoader
                loaderWrapperClassName={loaderWrapperClassName}
                loaderClassName={loaderClassName}
              />
            )}
          </ListOptionsWrapper>
        </>
      )}
    </HeadlessUI.Listbox>
  )
}
