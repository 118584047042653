import { LocaleEnum } from '@systemeio/ui-shared'
import React from 'react'
import { useLanguageSwitcher } from 'shared/components/language-switcher/hooks/use-language-switcher'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import useUser from '../../hooks/use-user'
import { LanguageSwitcher } from './language-switcher'

export function DesktopLanguageSwitcher() {
  const { i18n } = useLocoTranslation()
  const { isLoading } = useUser()
  const { handleChangeLanguage, isChangingLanguage } = useLanguageSwitcher()

  if (isLoading || !i18n) return <div className="h-6 w-[44px] animate-pulse rounded-sm bg-gray" />

  return (
    <LanguageSwitcher
      selectedLanguage={i18n.language as LocaleEnum}
      changeLanguage={handleChangeLanguage}
      isFetching={isChangingLanguage}
      buttonClassName={twMerge(
        'h-full flex items-center justify-around gap-2 focus:outline-none border-b-4 border-transparent focus-visible:border-blue',
        isChangingLanguage && 'animate-pulse',
      )}
      labelClassName={'w-5 text-sm font-bold text-white/90'}
      wrapperClassName={'h-full'}
    />
  )
}
