import { LocaleEnum } from '@systemeio/ui-shared'
import Script from 'next/script'
import React, { useEffect } from 'react'
import useUser from 'shared/hooks/use-user'

const beaconConfig = {
  display: {
    verticalOffset: 20,
    horizontalOffset: 20,
    verticalMobileOffset: 5,
    horizontalMobileOffset: 5,
  },
}

function HelpScoutScript() {
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      if (window.Beacon('info')) {
        window.Beacon('destroy')
      }
      if (user?.dashboardLocale === LocaleEnum.FRENCH) {
        window.Beacon('init', 'aaa8207b-5330-444e-874f-2932dbd59c04')
      } else {
        window.Beacon('init', '616a9e89-fc77-408c-995e-d7f589d575d7')
      }
      window.Beacon('config', beaconConfig)
    }
  }, [user?.dashboardLocale])

  return (
    <Script
      id={'help-scout'}
      dangerouslySetInnerHTML={{
        __html: `!function (e, t, n) {
            function a() {
              var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
              n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e)
            }
            if (e.Beacon = n = function (t, n, a) {
              e.Beacon.readyQueue.push({method: t, options: n, data: a})
            }, n.readyQueue = [], "complete" === t.readyState) return a();
            e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
          }(window, document, window.Beacon || function () {
          });`,
      }}
    />
  )
}

export default HelpScoutScript
