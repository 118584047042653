import { ErrorResponseWithViolationsType } from 'shared/types/error-response-content-interface'

export class ErrorWithViolations<P extends string = string> extends Error {
  public errors: ErrorResponseWithViolationsType<P>

  constructor(errorResponse: ErrorResponseWithViolationsType<P>) {
    super(errorResponse.detail)
    this.errors = errorResponse
  }
}
