import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { LimitType, SwrPaginationInterface } from 'shared/types/pagination-interface'
import { getPaginationAndFilterQueryString } from 'shared/utils/api/get-pagination-and-filter-query-string'
import useSWR from 'swr'
import { NOTIFICATIONS_API_URL } from '../api/notifications-api'
import {
  NotificationsApiInterface,
  NotificationsFilterInterface,
} from '../types/notification-types'

type UseNotificationsInterface = Omit<SwrPaginationInterface, 'limit'> & {
  shouldFetch?: boolean
  filter: NotificationsFilterInterface
  limit: LimitType | number
}

export interface HasUnreadResponse {
  hasUnread: boolean
}

export const useNotifications = ({
  limit,
  shouldFetch = true,
  pagination,
  filter,
}: UseNotificationsInterface) => {
  const { user } = useUser()
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    NotificationsApiInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const { fetcher: unreadFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    HasUnreadResponse
  >({
    method: RequestMethodsEnum.get,
  })

  const { data: notifications, mutate } = useSWR<NotificationsApiInterface>(() => {
    if (user && shouldFetch) {
      return `${NOTIFICATIONS_API_URL}/notifications?${getPaginationAndFilterQueryString({
        limit: limit as LimitType,
        pagination,
        filter: { ...filter },
      })}`
    }
    return null
  }, fetcher)

  const { data: notificationsMeta, mutate: mutateNotificationsMeta } = useSWR<HasUnreadResponse>(
    () => {
      if (user) {
        return `${NOTIFICATIONS_API_URL}/notifications/has-unread`
      }
      return null
    },
    unreadFetcher,
  )

  return { notificationsMeta, mutateNotificationsMeta, notifications, isFetching, mutate }
}
