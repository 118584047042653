import { Tooltip } from '@systemeio/ui-shared'
import React from 'react'
import { useHideItem } from 'shared/components/header/hooks/use-hide-item'
import { useIsActive } from 'shared/components/header/hooks/use-is-active'
import {
  MenuItemInterface,
  SingleItemDropdownInterface,
  SingleItemHrefInterface,
} from 'shared/components/header/types/menu-interface'
import { isFlatMenuItem } from 'shared/components/header/utils/is-flat-menu-item'
import { replaceHelpHref } from 'shared/components/header/utils/replace-help-href'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import ArrowLeftIcon from 'shared/icons/arrow-left-icon'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'

const DesktopMenuItem = ({ item }: MenuItemInterface) =>
  isFlatMenuItem(item) ? <TopMenuItemFlat {...item} /> : <TopMenuDropdown {...item} />

export default DesktopMenuItem

function TopMenuItemFlat(item: SingleItemHrefInterface) {
  const isActive = useIsActive(item)
  const { user, isWsAssistant } = useUser()
  const needToHide = useHideItem()
  if (!user) return null

  if (needToHide(item)) return null

  const href = isWsAssistant ? item.assistantLink || item.href : item.href

  return (item.showForRoles || user.roles).some(role => user.roles.includes(role)) ? (
    <div className="flex justify-center items-center relative transition-all group h-full">
      <LinkWithoutPrefetch
        className={`h-full flex items-center ${
          isActive ? 'text-blue' : ''
        } border-b-4 font-bold transition-all delay-100 tracking-wide hover:text-white hover:border-blue text-sm ${
          isActive ? 'border-blue text-white' : 'border-transparent text-gray-200'
        } outline-none focus-visible:border-blue focus-visible:text-white`}
        href={replaceHelpHref(href, user.dashboardLocale)}
        target={item.target}
      >
        {item.name}
      </LinkWithoutPrefetch>
    </div>
  ) : null
}

function TopMenuDropdown(item: SingleItemDropdownInterface) {
  const isActive = useIsActive(item)
  const needToHide = useHideItem()
  const filteredItems = item.dropdownItems.filter(item => !needToHide(item))
  if (filteredItems.length === 0) return null
  return (
    <Tooltip
      anchor={{
        gap: -5,
      }}
      stayOnHover={true}
      mode={'hover'}
      popoverClassName={'p-0 shadow-lg rounded-lg'}
      wrapperClassName={'h-[100%]'}
      label={<TopMenuDropdownPanel dropdownItems={filteredItems} />}
    >
      {show => (
        <button
          className={`h-full main-transition-colors delay-100 border-b-4 tracking-wide group-hover:text-white font-bold text-sm inline-flex items-center ${
            isActive || show ? 'border-blue text-white' : 'border-transparent text-gray-200'
          } focus:outline-none`}
        >
          {item.name}
          <ChevronDownIcon className="ml-2" />
        </button>
      )}
    </Tooltip>
  )
}

function TopSubMenu(item: SingleItemDropdownInterface) {
  const isActive = useIsActive(item)
  const needToHide = useHideItem()
  const filteredItems = item.dropdownItems.filter(item => !needToHide(item))
  if (filteredItems.length === 0) return null
  return (
    <Tooltip
      stayOnHover={true}
      mode={'hover'}
      popoverClassName={'p-0 rounded-lg shadow-lg z-50'}
      wrapperClassName={'h-[100%] w-[100%]'}
      className={'min-w-[100%]'}
      label={<TopMenuDropdownPanel dropdownItems={filteredItems} />}
      anchor={{
        to: 'right',
      }}
    >
      {show => (
        <button className={getSubItemClass(isActive || show)}>
          {item.name}
          <ArrowLeftIcon
            className={`rotate-180 group-hover:fill-blue main-transition-colors ${
              show || isActive ? 'fill-blue' : 'fill-gray-300'
            }`}
          />
        </button>
      )}
    </Tooltip>
  )
}

function TopMenuDropdownPanel({
  dropdownItems,
}: Pick<SingleItemDropdownInterface, 'dropdownItems'>) {
  const { user, isWsAssistant } = useUser()
  if (!user) return null
  return (
    <ul className="flex flex-col whitespace-nowrap text-gray-300 text-sm py-4">
      {dropdownItems.map(item => {
        if (isFlatMenuItem(item)) {
          return (
            (item.showForRoles || user.roles).some(role => user.roles.includes(role)) && (
              <li key={item.href}>
                <TopMenuSubItem
                  {...item}
                  href={isWsAssistant ? item.assistantLink || item.href : item.href}
                />
              </li>
            )
          )
        } else {
          return (
            <li key={item.name}>
              <TopSubMenu {...item} />
            </li>
          )
        }
      })}
    </ul>
  )
}

export function TopMenuSubItem(item: SingleItemHrefInterface) {
  const isActive = useIsActive(item)
  return (
    <LinkWithoutPrefetch
      data-test-id={item.dataTestId}
      className={getSubItemClass(isActive)}
      href={item.href}
    >
      {item.name}
    </LinkWithoutPrefetch>
  )
}

const getSubItemClass = (isActive: boolean) =>
  `flex main-transition-colors ${
    isActive ? 'text-blue' : 'text-gray-300'
  } px-7 py-1 hover:text-blue focus:outline-none focus-visible:text-blue w-full items-center justify-between group`
