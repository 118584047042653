import { FormSelectDataType, toast } from '@systemeio/ui-shared'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect, useMemo, useState } from 'react'
import { setWorkspace } from 'shared/api/workspace-change-api'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'
import { useSWRConfig } from 'swr'
import { twJoin, twMerge } from 'tailwind-merge'

interface WorkspaceSelectorProps {
  afterChange?: () => void
  className?: string
}

interface WorkspaceDataInterface {
  id: number | null
  image: string
  name: string
}

function WorkspaceSelector({ afterChange, className }: WorkspaceSelectorProps) {
  const { user, mutate } = useUser()
  const [id, setId] = useState<number | null>(null)
  const { pathname } = useRouter()

  const router = useRouter()
  const { cache } = useSWRConfig()
  const { t } = useLocoTranslation()
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const selectedWorkspace = user?.workspaceMemberships?.find(
      el => el.ownerId === user?.workspaceOwner,
    )
    setId(selectedWorkspace?.id ?? null)
  }, [user])

  const onWorkspaceChange = async (workspaceId: number | null) => {
    if (workspaceId === id) return
    const mainDashboardPath = '/'
    try {
      setIsFetching(true)
      NProgress.start()
      await setWorkspace({ currentWorkspaceMembership: workspaceId })
      afterChange?.()
      // @ts-ignore
      await cache.clear()
      await mutate()
      if (pathname !== mainDashboardPath) {
        await router.push(mainDashboardPath)
      }
      NProgress.done()
      setIsFetching(false)
      toast.success(t('dashboard.notifications.workspace_saved'))
    } catch (e) {
      setIsFetching(false)
      NProgress.done()
    }
  }

  const workspaces = useMemo(() => {
    if (!user) return
    const arr: WorkspaceDataInterface[] = [
      {
        id: null,
        name: user.subdomain,
        image: user.avatarUrl,
      },
    ]
    if (user.workspaceMemberships) {
      user.workspaceMemberships.forEach(workspace => {
        arr.push({
          id: workspace.id,
          name: workspace.name,
          image: workspace.image,
        })
      })
    }
    return arr
  }, [user])

  const workspacesData: FormSelectDataType<number | null>[] | undefined = workspaces?.map(el => ({
    id: el.id,
    caption: el.name,
    customCaption: (
      <div className={`gap-3 flex items-center text-start`}>
        <img className="h-7 w-7 rounded-full object-cover" src={el.image} alt={`${el.name}`} />
        {el.name}
      </div>
    ),
  }))

  const selectedWorkspace = workspaces?.find(el => el.id === id)

  const hasWorkspaces = !!user?.workspaceMemberships?.length

  return hasWorkspaces && selectedWorkspace ? (
    <ListBoxSelect
      buttonClassName={'rounded-md'}
      disabled={isFetching}
      value={id}
      onChange={onWorkspaceChange}
      data={workspacesData}
    >
      {open => (
        <div
          className={twMerge(
            `grid grid-flow-col items-center gap-3 rounded-md bg-transparent border border-gray/30 px-4 py-2 text-gray`,
            isFetching && 'bg-gray-300/50 animate-pulse',
            className,
          )}
        >
          <img
            className="h-[28px] min-w-[28px] w-[28px] rounded-full object-cover"
            src={selectedWorkspace.image}
            alt={`${selectedWorkspace.name}`}
          />
          <span className="text-start truncate">{selectedWorkspace.name}</span>
          <ChevronDownIcon
            className={twJoin(`h-3 w-3 transition-transform`, open && 'rotate-180')}
            aria-hidden="true"
          />
        </div>
      )}
    </ListBoxSelect>
  ) : null
}

export default WorkspaceSelector
