import debounce from 'lodash.debounce'
import { useLayoutEffect, useRef, useState } from 'react'
import Avatar from 'shared/components/header/components/avatar'
import { DesktopMenu } from 'shared/components/header/components/desktop-menu'
import SideMenu from 'shared/components/header/components/side-menu'
import SioButton from 'shared/components/header/components/sio-button'
import WorkspaceSelector from 'shared/components/header/components/workspace-selector'
import { DesktopLanguageSwitcher } from 'shared/components/language-switcher/desktop-language-switcher'
import useUser from 'shared/hooks/use-user'

function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const { isLoading, user } = useUser()
  const navRef = useRef<HTMLDivElement>(null)
  const workspaceRef = useRef<HTMLDivElement>(null)
  const sideMenuRef = useRef<HTMLDivElement>(null)
  const desktopMenuRef = useRef<HTMLDivElement>(null)
  const avatarRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const handleResize = debounce(
      () => {
        const nav = navRef.current
        const desktopMenu = desktopMenuRef.current
        const sideMenu = sideMenuRef.current
        const avatar = avatarRef.current
        const workspace = workspaceRef.current

        if (!desktopMenu || !sideMenu || !nav || !avatar || !workspace) return

        const showDesktopElements = () => {
          desktopMenu.classList.add('lg:flex')
          avatar.classList.add('lg:flex')
          workspace.classList.add('lg:flex')
          sideMenu.classList.add('lg:hidden')
        }

        const showMobileElements = () => {
          desktopMenu.classList.remove('lg:flex')
          avatar.classList.remove('lg:flex')
          workspace.classList.remove('lg:flex')
          sideMenu.classList.remove('lg:hidden')
        }

        if (nav.scrollWidth > nav.offsetWidth) {
          showMobileElements()
        } else {
          showDesktopElements()

          if (nav.scrollWidth > nav.offsetWidth) {
            showMobileElements()
          }
        }
      },
      300,
      {
        leading: true,
        trailing: true,
      },
    )

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isLoading, user?.dashboardLocale])

  return (
    <nav ref={navRef} className="top-0 bg-darkblue z-10">
      <div className="flex w-full mx-auto items-center justify-between h-[69px] text-white z-10 px-4 sm:px-6 md:px-14 gap-4">
        <div className="flex items-center gap-4">
          <SioButton />

          <div ref={workspaceRef} className="hidden lg:flex">
            <WorkspaceSelector />
          </div>
        </div>

        <div ref={desktopMenuRef} className="hidden lg:flex ml-auto h-full">
          <DesktopMenu />
        </div>

        <div
          ref={avatarRef}
          className="hidden lg:flex h-full items-center justify-around gap-4 shrink-0"
        >
          <Avatar />
          <DesktopLanguageSwitcher />
        </div>

        <div ref={sideMenuRef} className="flex lg:hidden">
          <SideMenu isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
    </nav>
  )
}

export default Header
