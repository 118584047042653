import { LocaleEnum, Tooltip, localeLabels, localeNames } from '@systemeio/ui-shared'
import React from 'react'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'
import { twMerge } from 'tailwind-merge'

export interface LanguageSwitcherProps {
  selectedLanguage: LocaleEnum
  changeLanguage: (locale: LocaleEnum) => void
  listOfLanguages?: LocaleEnum[]
  isFetching?: boolean
  buttonClassName?: string
  menuClassName?: string
  labelClassName?: string
  wrapperClassName?: string
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  selectedLanguage,
  changeLanguage,
  listOfLanguages = Object.values(LocaleEnum),
  isFetching,
  buttonClassName,
  labelClassName,
  wrapperClassName,
}) => {
  return (
    <Tooltip
      anchor={{
        gap: -5,
      }}
      wrapperClassName={wrapperClassName}
      stayOnHover={true}
      className={'h-full'}
      popoverClassName={'p-0 shadow-lg rounded-lg'}
      label={
        <div
          className={twMerge(
            'max-h-[400px] overflow-hidden overflow-y-auto customScroll rounded-lg bg-white shadow-lg z-50',
            isFetching && 'pointer-events-none',
          )}
        >
          <ul className={'flex flex-col whitespace-nowrap text-gray-300 text-sm py-4'}>
            {listOfLanguages.map(language => (
              <li key={language}>
                <button
                  disabled={language === selectedLanguage}
                  className={`w-full text-start px-7 py-1 hover:text-blue focus:outline-none focus-visible:text-blue main-transition-colors ${
                    language === selectedLanguage ? 'text-blue' : 'text-gray-300'
                  }`}
                  data-test={`language-switcher-option-button-${language}`}
                >
                  <p onClick={() => changeLanguage(language)}>{localeNames[language]}</p>
                </button>
              </li>
            ))}
          </ul>
        </div>
      }
    >
      <button
        disabled={isFetching}
        className={twMerge('flex items-center gap-2 text-sm font-normal', buttonClassName)}
        data-test="language-switcher-button"
      >
        <div className={twMerge('flex-1 text-start capitalize', labelClassName)}>
          {selectedLanguage && localeLabels[selectedLanguage]}
        </div>
        <ChevronDownIcon />
      </button>
    </Tooltip>
  )
}
