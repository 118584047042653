import { LocaleEnum } from '@systemeio/ui-shared'
import { i18n as i18nGlobal } from 'next-i18next'
import { LocalizeValues } from 'shared/hooks/use-loco-translation'

export const addLanguageResource = async (
  locale: LocaleEnum,
  ns: LocalizeValues,
  i18nLocal: NonNullable<typeof i18nGlobal>,
) => {
  const additionalTranslations = await import(`../../../../../public/locales/${locale}/${ns}.json`)
  i18nLocal.addResourceBundle(locale, ns, additionalTranslations, true, true)
}
