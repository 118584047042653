import { LocaleEnum } from '@systemeio/ui-shared'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import { changeLanguage as changeLanguageUnAuthorizedUser } from 'shared/api/public-user-api'
import { changeLanguage } from 'shared/api/user-api'
import { addLanguageResource } from 'shared/components/language-switcher/utils/add-language-resource'
import { LocalizeValues, useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { LOCALE_COOKIE } from '../constants'

export const useLanguageSwitcher = () => {
  const { i18n } = useLocoTranslation()
  const { dashboardUser, publicUser, mutate: mutateUser, mutatePublic } = useUser()
  const [isChangingLanguage, setIsChangingLanguage] = useState(false)

  const changeNextLanguage = useCallback(
    async (locale: LocaleEnum) => {
      for await (const ns of i18n.options.ns as LocalizeValues[]) {
        if (i18n.hasResourceBundle(locale, ns)) continue
        await addLanguageResource(locale, ns, i18n)
      }

      if (i18n.language !== locale) {
        i18n.changeLanguage(locale)
      }

      const currentLocaleCookie = Cookies.get(LOCALE_COOKIE)
      if (currentLocaleCookie !== locale) {
        Cookies.set(LOCALE_COOKIE, locale)
      }
    },
    [i18n],
  )

  useEffect(() => {
    const loadTranslations = async () => {
      if (!dashboardUser && !publicUser) return

      const locale =
        dashboardUser?.dashboardLocale || publicUser?.dashboardLocale || LocaleEnum.ENGLISH

      await changeNextLanguage(locale)
    }
    loadTranslations()
  }, [changeNextLanguage, dashboardUser, publicUser])

  const handleChangeLanguage = async (locale: LocaleEnum) => {
    try {
      setIsChangingLanguage(true)
      if (dashboardUser) {
        await changeLanguage(locale)
      } else if (publicUser) {
        await changeLanguageUnAuthorizedUser(locale)
      }
      await mutateUser(
        () =>
          // due to fallback data in useSWR, we need to use data directly, not from mutate
          dashboardUser && {
            ...dashboardUser,
            dashboardLocale: locale,
          },
        false,
      )
      await mutatePublic(
        () =>
          dashboardUser && {
            ...dashboardUser,
            dashboardLocale: locale,
          },
        false,
      )
    } catch (e) {
      console.log(e)
    } finally {
      setIsChangingLanguage(false)
    }
  }
  return { handleChangeLanguage, isChangingLanguage }
}
